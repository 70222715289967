import styles from "./banner.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

//import video from "../../../assets/videos/bg-movie_720.mp4";
import Image1 from "../../assets/images/banner-3.png";
import Image2 from "../../assets/images/banner-2.jpeg";
import Image3 from "../../assets/images/banner-3.jpeg";
import Image4 from "../../assets/images/banner-1.png";

import { Autoplay } from "swiper/modules";

export default function Banner() {
  return (
    <main className={`${styles.banners} w-100 zIndex1`} id="banner">
      <div className={`${styles.positionReleative} w-100 h-100`}>
        <div className={styles.darkBg}></div>
        {/* <div
          className={`${styles.bannerMobileView} w-100  h-100 view-onmobile`}
        >
          <img src={Image1} className={styles.bannerMobileView} />
        </div>
        <div
          className={`${styles.bannerDesktopView} w-100 h-100 view-ondesktop`}
        >
          <img src={Image3} />
          <img src={Image1} />
          <img src={Image2} />
        </div> */}
        <Swiper
          navigation={false}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            760: {
              slidesPerView: 1,
            },
            960: {
              slidesPerView: 3,
            },
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <div className={styles.bannerImageAlign}>
              <img src={Image3} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.bannerImageAlign}>
              <img src={Image1} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`${styles.bannerImageAlignLastImage}`}>
              <img src={Image2} />
            </div>
          </SwiperSlide>
        </Swiper>
        <h3 className={styles.info}>
          Made-to-order fashion with expert styling, delivered to you
        </h3>
      </div>
    </main>
  );
}
