import "./App.css";
import { createContext, useContext, useState } from "react";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Banner from "./components/banner/Banner";
import BookingSteps from "./components/bookingsteps/BookingSteps";
import Choices from "./components/choices/Choices";
import Feedbacks from "./components/feedbacks/Feedbacks";
import FaqSection from "./components/faqSection/FaqSection";
import DesignerMessage from "./components/designerMessage/DesignerMessage";
import Works from "./components/works/Works";
import Offers from "./components/offers/Offers";
// import Scroll from "../utils/Scroll";
//import WhatAppIcon from "../assets/icon/whatsapp-icon.png";
// import StickyBox from "react-sticky-box";
import WhatsappImage from "./assets/icon/whatsapp-icon.png";
import { AppContext } from "./AppContext";

function App() {
  const [isMenuBarOpen, setIsMenuBarOpen] = useState("");
  return (
    <AppContext.Provider value={{ isMenuBarOpen, setIsMenuBarOpen }}>
      <div>
        <Header />
        <Banner />
        {!isMenuBarOpen && <Offers />}

        <BookingSteps id="booking-steps" />
        <Choices id="choices" />
        <DesignerMessage id="designer-message" />
        <Works id="works" />
        <Feedbacks id="customer-feedbacks" />
        <FaqSection />
        <Footer />
        {!isMenuBarOpen && <WhatsappIcon />}
      </div>
    </AppContext.Provider>
  );
}

export default App;

const WhatsappIcon = () => {
  return (
    <div className="floating-icon">
      <a href="https://wa.me/+919944590420" target="blank">
        <img src={WhatsappImage} width={"48"} />
      </a>
    </div>
  );
};
